<template>
  <b-form @submit.prevent="save">
    <b-modal id="modalChangeResults" :title="$t('changeResults')">
      <div class="row text-center">
        <div class="col-6">
          <div class="form-group">
            <label for="team_local">{{ fixture.team_local_name }}</label>
            <b-form-input type="number" min="0" class="text-center font-weight-bold" id="team_local" size="lg" v-model="fixture.local_goals"></b-form-input>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="team_visit">{{ fixture.team_visit_name }}</label>
            <b-form-input type="number" id="team_visit" size="lg" min="0" class="text-center font-weight-bold" v-model="fixture.visit_goals"></b-form-input>
          </div>
        </div>
      </div>
      <template slot="modal-footer">
        <b-button type="button" @click="$root.$emit('bv::hide::modal', 'modalChangeResults')">{{ $t('cancel') }}</b-button>
        <b-button type="submit" variant="primary">{{ $t('save') }}</b-button>
      </template>
    </b-modal>
  </b-form>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    fixture: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      fixtureId: this.$route.params.fixtureId
    }
  },
  computed: {
    ...mapGetters(['lang', 'console'])
  },
  methods: {
    save () {
      const payload = {
        local_goals: this.fixture.local_goals,
        visit_goals: this.fixture.visit_goals
      }
      const path = `auth/${this.lang}/${this.console}/teams/results/${this.fixtureId}/update-result`
      this.$axios.put(path, payload).then((response) => {
        this.$toastr.success(response.data.message)
        this.$emit('updateResults', {
          local_goals: this.fixture.local_goals,
          visit_goals: this.fixture.visit_goals
        })
        this.$root.$emit('bv::hide::modal', 'modalChangeResults')
      })
    }
  }
}
</script>
